import React from 'react';

const Home = () => {
    return (
        <div className="section">
            <h2> Welcome to my website! </h2>
            <p> Please click on the above tabs to find out more about me! </p>
        </div>
    );
};

export default Home;