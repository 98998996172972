import React from 'react';

const Contact = () => {
    return (
        <div className="section">
            <h2> Contact Me </h2>
            <p> Please email me at <a className="section-link" href="mailto:akash.jairam21@gmail.com">akash.jairam21@gmail.com</a> and/or connect with me on <a className="section-link" href="https://www.linkedin.com/in/akjairam">LinkedIn</a>!</p>
        </div>
    );
};

export default Contact;
