import React from 'react';
import './fonts.css';
import '../App.css';
import CompanyModal from './CompanyModal.js'
const About = () => {
    return (
        <div className="section">
            <h2> About Me </h2>
            <p>
                I'm a software engineer with a passion for innovation and problem solving. <br/> <br/>

                My journey began when I majored in Computer Science at Brooklyn College. During my time there, I participated in hackathons, coding challenges as well as played for the <a className="section-link" href="https://www.brooklyncollegeathletics.com/roster.aspx?rp_id=1897">cross country</a> and
                 <a className="section-link" href="https://www.brooklyncollegeathletics.com/sports/mens-tennis/roster/akash-jairam/2278"> tennis team</a>. <br/> <br/>

                I was fortunate enough to intern at JP Morgan in my Junior year. <br/> <br/>

                After graduation in 2019, I started (and still currently am) working at Mastercard!

                If you'd like to know more about my experience, please click on the below logos.
            </p>
            <div className="flex-container">
                <div className="flex-item">
                    <CompanyModal company="mastercard"/>
                </div>
                <div className="flex-item">
                    <CompanyModal company="chase"/>
                </div>
            </div>
        </div>
    );
}

export default About;
