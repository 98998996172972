import React from 'react';
import { Link } from 'react-router-dom';

const Projects = () => {
    return (
        <div className="section">
            <h2> Projects </h2>
            <p>Below are some of my projects: </p>
            <ol className="projects-list">
                <li>Test your reaction time with my <Link className='project-link' to="/american-flag-game">American Flag Game</Link>!</li>
            </ol>
            <h3> Note: I am in the process of migrating my other projects. Please stay tuned.</h3>
        </div>
    );
};

export default Projects
