import React, { useState, useEffect } from 'react';
import './AmericanFlagGame.css'; // Import your CSS file for styling

const AmericanFlagGame = () => {
  const [startTime, setStartTime] = useState(new Date().getTime());

  const makeItemAppear = () => {
    const top = Math.floor(Math.random() * 200);
    const left = Math.floor(Math.random() * 850);
    const width = Math.floor(Math.random() * 250) + 50;

    const flagElement = document.getElementById('disappear');
    const usaElement = document.getElementById('USA');

    usaElement.style.width = width + 'px';
    usaElement.style.height = width + 'px';
    flagElement.style.top = top + 'px';
    flagElement.style.left = left + 'px';
    flagElement.style.display = 'block';
    setStartTime(new Date().getTime());
  };

  const appearAfterDelay = () => {
    setTimeout(makeItemAppear, Math.random() * 2000);
  };

  useEffect(() => {
    appearAfterDelay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFlagClick = () => {
    const flagElement = document.getElementById('disappear');
    flagElement.style.display = 'none';

    const endTime = new Date().getTime();
    const timeTaken = (endTime - startTime) / 1000;
    const timeTakenElement = document.getElementById('timeTaken');
    if (timeTakenElement) {
      timeTakenElement.innerHTML = `${timeTaken.toFixed(2)}s`;
    }

    appearAfterDelay();
  };

  return (
    <div id="AmericanFlagGamePage">
      <h1>Happy 4th of July!</h1>
      <h1>Test Your Reactions!</h1>
      <p>Click on the American Flag as quickly as you can!</p>
      <p className="bold">Your time: <span id="timeTaken"></span></p>
      <div className="flag" id="disappear" onClick={handleFlagClick}>
        <img src={require("../img/USAFlag.jpg")} id="USA" alt="American Flag" />
      </div>
    </div>
  );
};

export default AmericanFlagGame;
