import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Projects from './components/Projects';
import AmericanFlagGame from './components/AmericanFlagGame';
const App = () => {
    return (
     <Router>
        <div>
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Home/>} />
                <Route path="/about" element={<About/>} />
                <Route path="/contact" element={<Contact/>} />
                <Route path="/projects" element={<Projects/>} />
                <Route path="/american-flag-game" element={<AmericanFlagGame/>} />
            </Routes>
        </div>
     </Router>
    );
}

export default App;
