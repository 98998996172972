import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade } from '@mui/material';
import './CompanyModal.css';

const CompanyModal = ({ company }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div>
      {company === 'mastercard' ? (
        <img
          className="mastercard"
          src={require('../img/mastercard.png')}
          alt="Mastercard Logo"
          onClick={openModal}
        />
      ) : (
        <img
          className="chase"
          src={require('../img/chase.png')}
          alt="JP Morgan Chase Logo"
          onClick={openModal}
        />
      )}

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          invisible: true,
        }}
      >
        <Fade in={isModalOpen}>
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              {company === 'mastercard' ? (
                <div className="modal-content">
                  <div><h1>Mastercard</h1></div>
                  <div>
                    During my 4+ years here, I have worked on mostly Java/Spring
                    Boot applications. In addition, I built two Python applications from scratch. <br />
                    I was also fortunate enough to win Mastercard's AI By
                    Default Challenge and the Data and Services hackathon!
                  </div>
                </div>
              ) : (
                <div className="modal-content">
                  <div><h1>JP Morgan Chase</h1></div>
                  <div>
                      During my 10 week internship, I got the opportunity to create REST APIs using both Java/Spring Boot
                      and Python/Flask. In addition, I created multiple containers using Dockerfiles for testing.
                  </div>
                </div>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CompanyModal;
